import Navbar from "./components/Navbar/Navbar";
import one from "./assets/gallery/1.jpeg"
import two from "./assets/gallery/2.jpeg"
import three from "./assets/gallery/3.jpeg"
import four from "./assets/gallery/4.jpeg"
import five from "./assets/gallery/5.jpeg"
import six from "./assets/gallery/6.jpeg"
import "./Gallery.css";

export default function Gallery() {
  return (
    <div id="gallery">
      <Navbar />
      <div id="image-grid">
        <img src={five}/>
        <img src={two}/>
        <img src={three}/>
        <img src={one}/>
        <img src={six}/>
      </div>
    </div>
  );
}