import React, { useState } from 'react';
import { Link } from "react-router-dom";
import './Navbar.css';
import './Burger.css';
import logo from "../../assets/logo.png";

export default function Navbar( {coloredBg = true} ) {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = function() {
    setMenuOpen(!menuOpen);
  }

  return (
    <div id="navbar" className={menuOpen ? "expanded" : null}>
      <div id="logo-and-burger">
        <div id="center-logo-empty"></div>
        <Link to="/"><img src={logo} alt="Coastal Charm logo"/></Link>
        <button onClick={toggleMenu}>
          <div id="burger" className={menuOpen ? "expanded" : null}>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </button>
      </div>
        <div id="links">
          <Link to="/order">order</Link>
          <Link to="/book">book an event</Link>
          <Link to="/gallery">gallery</Link>
          <Link to="/about">about</Link>
      </div>
      <div id="center-links-empty"></div>
    </div>
  );
}