import Navbar from "../components/Navbar/Navbar";

export default function Order() {
  return (
    <>
      <Navbar />
      <div className="main-body">
        <h1>coming soon</h1>
      </div>
    </>
  )
}