import Navbar from "./components/Navbar/Navbar";
import "./About.css";
import us from "./assets/us.jpeg";

export default function About() {
  return (
    <div id="about">
      <Navbar />
      <h2>about</h2>
      <div className="main-body">
        <div className="showcase-section">
          <div>
            <h2>what we do</h2>
            <span><b>popups</b> at boutiques and businesses <br /></span>
            <span><b>private parties</b> bachelorette, birthdays, wine nights & more<br /></span>
            <span><b>fairs and festivals</b></span>
            <h2>pricing</h2>
            <table>
              <tr>
                <td><b>necklace (16 in.)</b></td>
                <td>$38</td>
              </tr>
              <tr>
                <td><b>necklace (18+ in.)</b></td>
                <td>$40</td>
              </tr>
              <tr>
                <td><b>bracelet (any length)</b></td>
                <td>$22</td>
              </tr>
              <tr>
                <td><b>standard charm</b></td>
                <td>$3 ea.</td>
              </tr>
              <tr>
                <td><b>specialty charm</b></td>
                <td>$5 ea.</td>
              </tr>
            </table>
          </div>
          <img src={us} alt={"owners Emily and Sarah at a popup"}/>
        </div>
      </div>
    </div>
  );
}